import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { IconButton, Skeleton, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import Flexbox from "../components/Flexbox";
import Bodytext from "../components/Bodytext";
import { useEffect, useState } from "react";
import CustomButton from "../components/styles/Custombutton";
import Toast from "../components/Toast";
import cookies from "../utilities/Cookies";
import Highlight from "react-highlight";
import Copycode from "../components/Copycode";
import { ChevronLeft } from "@mui/icons-material";

const HTMLEditor = ({ title }) => {
    document.querySelector("title").innerHTML = title

    const { id } = useParams()
    const { data: plugin, isLoading, error } = useFetch(`/api/plugins/${id}`)

    const navigate = useNavigate()

    const [user, setUser] = useState({})

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState("")
    const [inputs, setInputs] = useState([])
    const [variablesIndex, setVariablesIndex] = useState(0)
    const [divSnipet, setDivSnipet] = useState("")
    const [snipet, setSnipet] = useState("")
    const [showSnipet, setShowSnipet] = useState(false)
    const [btnText, setBtnText] = useState("Proceed to next input →")

    const destructureInputs = (inputs) => {
        /**
         * This function destructures the inputs (array of objects) into a single object string
         * Dependency: inputs - an array of object containing the function argument and it's value
         * return: The function returns a single string object
         * argument: array of object containing the name and value of the plugin function argument
         */
        let string_object = '({'
        for (let i in inputs) {
            if (parseInt(i) + 1 === inputs.length)
                string_object += `${Object.keys(inputs[i])}: "${Object.values(inputs[i])}"`
            else
                string_object += `${Object.keys(inputs[i])}: "${Object.values(inputs[i])}", `
        }
        string_object += '})'
        console.log('string object', string_object)
        return string_object
    }

    const generateCode = async (arr) => {
        /**
         * This function creates the code snipet for the user
         * Dependency: Plugin - This function depends on the plugin being customised
         * argument: Recieves and array of object containing the name and value of the plugin function arguement
         */
        let res = await fetch('/api/v1/baseurl', { credentials: 'include', method: 'GET' })
        let response = await res.json()
        if (response.success) {
            let baseUrl = `${response.data}/${plugin.type}/${plugin.filename}/${user.id}`
            let returnedArgument = destructureInputs(arr)
            let prototypeAndClassname = plugin.prototype // This gets the prototype and classname of the plugin for testimonials, faqs and other plugins with html properties
            let prototype = prototypeAndClassname.split("|")[0] // get the prototype
            let classname = prototypeAndClassname.split("|")[1].replaceAll(" ", "") // get the classname
            const regex = /\([^)]*\)/g; // Match any section enclosed in parentheses
            prototype = prototype.replace(regex, "")
            prototype = prototype.replace("  ", " ") // remove excess spacing caused by regex
            const snip = `<script src="${baseUrl}"></script>\n<script>\n\t${prototype}${returnedArgument}\n</script>`
            setSnipet(snip)
            setDivSnipet(`<div class="${classname}"></div>`)
            setShowSnipet(true)
            setLoading(false)
        } else {
            setToastMsg(response.msg)
            setSeverity("error")
            setOpen(true)
            console.log(response.error)
        }
    }

    const handleInput = (varLen) => {
        if (input !== "") {
            console.log(variablesIndex + 1)
            if ((variablesIndex + 1) < varLen) {
                // save current input value
                let property_name = plugin.variables[variablesIndex].argument
                let obj = {}
                obj[property_name] = input
                let arr = [...inputs, obj] // update arr with existing inputs array and the current input value
                setInputs(arr)
                setInput("") // clear input value
                setVariablesIndex(variablesIndex + 1)
                if (variablesIndex + 2 === varLen)
                    setBtnText("Generate Code")
            } else {
                // save current input value
                let property_name = plugin.variables[variablesIndex].argument
                let obj = {}
                obj[property_name] = input
                let arr = [...inputs, obj] // update arr with existing inputs array and the current input value
                setInputs(arr)
                console.log(arr)
                // generate code
                setLoading(true)
                generateCode(arr)
            }
        } else {
            setToastMsg("Provide valid value")
            setSeverity("error")
            setOpen(true)
        }

    }

    useEffect(() => {
        const stringify_user = cookies.getCookies('user');
        if (stringify_user === '' || stringify_user === undefined || stringify_user === null || stringify_user.length < 10) {
            navigate('/');
        } else {
            setUser(JSON.parse(stringify_user))
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="editor-page">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="mid margin-auto">
                <Flexbox alignItems="center" justifyContent="center" style={{ borderRadius: '1000px', backgroundColor: '#f0f0f0', height: '30px', width: '30px' }}>
                    <IconButton onClick={() => {
                        window.history.back()
                    }}>
                        <ChevronLeft style={{ fontSize: '20px'}} />
                    </IconButton>
                </Flexbox>
            </div>
            {isLoading && (
                <div className="mid margin-auto">
                    <Skeleton variant="rounded" width={"100%"} height={"120px"} />
                    <Spacebox padding="10px" />
                    <Skeleton variant="rounded" width={"100%"} height={"70px"} />
                    <Spacebox padding="2.5px" />
                    <Skeleton variant="rounded" width={"100%"} height={"70px"} />
                </div>
            )}
            {error && (
                <div>
                    <Spacebox padding="10px" />
                    <Flexbox justifyContent="center">
                        <Bodytext size="20px">
                            An error occured while getting plugin
                        </Bodytext>
                    </Flexbox>
                </div>
            )}
            {plugin && (
                <div>
                    <Typography variant="h4" className="bold" textAlign="center">
                        {plugin.name}
                    </Typography>
                    <Typography variant="h6" className="bold" textAlign="center" style={{ color: 'var(--secondary)' }}>
                        Code Editor
                    </Typography>
                    <Spacebox padding="10px" />
                    <div className="mid margin-auto">
                        {!showSnipet && (
                            <div>
                                <Spacebox padding="5px">
                                    <Flexbox alignItems="center" justifyContent="space-between">
                                        <small style={{ opacity: .8 }}>{plugin.variables[variablesIndex].name}</small>

                                        <small style={{ opacity: .8 }}>Input {variablesIndex + 1} of {plugin.variables.length}</small>
                                    </Flexbox>
                                </Spacebox>
                                <input
                                    type="text"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    placeholder={plugin.variables[variablesIndex].name}
                                />
                                <Spacebox padding="5px">
                                    <small style={{ opacity: .5 }}>
                                        {plugin.variables[variablesIndex].helper}
                                    </small>
                                </Spacebox>
                                <Spacebox padding="5px" />
                                <CustomButton backgroundColor="var(--primary)" borderRadius="20px" color="white" handleClick={() => handleInput(plugin.variables.length)} padding="20px 0" className="fullwidth">
                                    {loading ? 'Please wait...' : btnText}
                                </CustomButton>
                            </div>
                        )}
                        {showSnipet && (
                            <div>
                                <Copycode action={() => {
                                    window.navigator.clipboard.writeText(divSnipet)
                                    setToastMsg("Code Copied")
                                    setSeverity('success')
                                    setOpen(true)
                                }} />
                                <Highlight className="html">
                                    {divSnipet}
                                </Highlight>
                                <Spacebox padding="5px">
                                    <small style={{ opacity: '.5' }}>Copy the above code and paste in the body section of your page where you want the {plugin.name}.</small>
                                </Spacebox>
                            </div>
                        )}
                        {showSnipet && (
                            <div>
                                <Spacebox padding="10px" />
                                <Copycode action={() => {
                                    window.navigator.clipboard.writeText(snipet)
                                    setToastMsg("Code Copied")
                                    setSeverity('success')
                                    setOpen(true)
                                }} />
                                <Highlight className="html">
                                    {snipet}
                                </Highlight>
                                <Spacebox padding="5px">
                                    <small style={{ opacity: '.5' }}>Copy the above code and paste in the footer section of your page</small>
                                </Spacebox>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default HTMLEditor;