import { Divider, IconButton, Typography } from "@mui/material";
import Flexbox from "./Flexbox";
import { ChevronRight } from "@mui/icons-material";
import Spacebox from "./styles/Spacebox";

const Step = ({ title, children, open, setOpen }) => {
    return (
        <div className="step">
            <div className="step-header">
                <Flexbox justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" className="bold">
                        {title}
                    </Typography>
                    <IconButton onClick={() => setOpen(!open)}>
                        <ChevronRight style={{transform: open ? 'rotate(90deg)' : 'rotate(0deg)'}} />
                    </IconButton>
                </Flexbox>
            </div>
            <Spacebox padding="5px" />
            {open && <div className="step-body">
                {children}
            </div>}
            <Spacebox padding="5px" />
            <Divider />
        </div>
    );
}

export default Step;