import { Typography } from "@mui/material";
import Grid from "../components/styles/Grid";
import Testimonies from "../components/Testimonies";
import Flexbox from "../components/Flexbox";
import CustomButton from "../components/styles/Custombutton";
import Spacebox from "../components/styles/Spacebox";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import '../css/auth.css'
import { useNavigate } from "react-router-dom";
import Toast from "../components/Toast";
import cookies from "../utilities/Cookies";
import { updateuser } from "../features/users";
import requests from "../handlers/requests";

const Signup = ({ title }) => {

    document.querySelector("title").innerHTML = title;



    const urlParams = new URLSearchParams(window.location.search)
    const ref_param = urlParams.get('ref')

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')


    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()



    function generateAvatar() {
        const avatars = ['cat', 'chicken', 'deer', 'dog', 'dog2', 'giraffe', 'koala', 'meerkat', 'panda', 'puffer-fish', 'rabbit', 'sea-lion', 'snake'];
        let min = 0
        let max = avatars.length - 1
        let random = Math.floor(Math.random() * (max - min + 1)) + min

        let selected_image = avatars[random]
        let imageurl = `/avatars/${selected_image}.png`

        return imageurl
    }

    const handleSignup = async () => {
        setLoading(true)
        if (email.includes('@') && password.length > 7 && firstname !== '' && lastname !== '') {
            let res = await fetch('https://pro.ip-api.com/json/?key=CrUh3eTzb21pTcf');
            let ip_ = await res.json();
            const successful = ip_.countryCode ? true : false
            requests.makePost('/register', {
                firstname, email, lastname, password, avatar: generateAvatar(), country_code: successful ? ip_.countryCode : 'ERR', country: successful ? ip_.country : 'ERR',
                ref: ref_param ? ref_param : 'i9UGbNNmvKONLeCib96U'
            }, setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    cookies.setCookies('user', JSON.stringify(res.user), 0.5)
                    dispatch(updateuser(res.user))
                    setTimeout(() => {
                        navigate('/dashboard')
                    }, 3000);
                }, "Account Created Successfully"
            )
        } else {
            setToastMsg('Invalid input')
            setOpen(true)
            setSeverity('error')
            setLoading(false)
        }

    }

    useEffect(() => {
        requests.makeGet("/authenticate", setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                if (res.loggedIn) {
                    navigate('/dashboard')
                }
            }, null)
        //eslint-disable-next-line
    }, [])


    return (
        <div className="signup-page hide-scrollbar">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Grid gap="20px" grid="2" className="remove-grid-med-nd-down maxHeight100 overflowHidden">
                <Flexbox alignItems="center" justifyContent="center" className="full gradient-animate hide-on-med-and-down">
                    <div className="margin-auto glass" style={{ width: '80%', color: 'white', padding: '20px' }}>
                        <Testimonies />
                    </div>
                </Flexbox>
                <Spacebox padding="5px" className="gradient-animate hide-on-large-only" />
                <div style={{ padding: '20px' }} className="maxHeight100 overflowY-scroll">
                    <Flexbox justifyContent="space-between" alignItems="center">
                        <div className="logo-icon">
                            <img src="/assets/logo_icon.png" alt="docly icon" />
                        </div>
                        <CustomButton backgroundColor="#f0f0f0" color="black" borderRadius="50px" padding="15px 30px" handleClick={() => navigate("/")}>
                            {"Already Have An Account? Login"}
                        </CustomButton>
                    </Flexbox>
                    <Spacebox padding="20px" />
                    <Typography variant="h4" className="bold">
                        Get Started
                    </Typography>
                    <Spacebox padding="2px" />
                    <Typography variant="body2" sx={{ color: '#ababab' }}>
                        Welcome to Scripts! Let's get started by creating your account
                    </Typography>
                    <Spacebox padding="20px" />
                    <div className="mid">
                        <small>Firstname</small>
                        <input
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            className="fullwidth"
                            type="text"
                        />
                        <Spacebox padding="10px" />
                        <small>Lastname</small>
                        <input
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            className="fullwidth"
                            type="text"
                        />
                        <Spacebox padding="10px" />
                        <small>Email</small>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="fullwidth"
                            type="email"
                        />
                        <Spacebox padding="10px" />
                        <small>Password</small>
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="fullwidth"
                            type="password"
                        />
                        <Spacebox padding="2px" />
                        <small style={{ color: 'grey' }}>Password should be over 7 characters</small>
                        <Spacebox padding="10px" />
                        <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="50px" padding="15px 0px" className="fullwidth" handleClick={handleSignup}>
                            {loading ? "Authenticating..." : "Signup"}
                        </CustomButton>
                        <Spacebox padding="20px" />
                    </div>
                </div>
            </Grid>
        </div>
    );
}

export default Signup;