import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useFetch = (url) => {

    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        const abortCont = new AbortController();

        fetch(url, {
            credentials: "include",
            signal: abortCont.signal
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    setData(res.data);
                    setError(null);
                    setIsLoading(false)
                } else {
                    if (res.msg === "Invalid Auth Token" || res.msg === "Unauthorised User")
                        navigate('/')
                    setError(res.msg);
                    setData(null);
                    setIsLoading(false)
                }
            }).catch(err => {
                if (err === 'AbortError') {
                    console.log('Fetch terminated');
                } else {
                    console.log("Error message: ", err.message);
                    setData(null);
                    setIsLoading(false);
                    setError('Error with fetch');
                }
            })

        return () => abortCont.abort();
        //eslint-disable-next-line
    }, [url])
    return { data, error, isLoading };
}

export default useFetch;