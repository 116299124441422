import { Container, IconButton, Skeleton, Typography } from "@mui/material";
import Toast from "../components/Toast";
import Grid from "../components/styles/Grid";
import Bodytext from "../components/Bodytext";
import { useEffect, useState } from "react";
import Spacebox from "../components/styles/Spacebox";
import Flexbox from "../components/Flexbox";
import String from "../utilities/Strings";
import { CancelOutlined, CheckBoxOutlined, Link } from "@mui/icons-material";
import requests from "../handlers/requests";
import ArrowPagination from "../components/ArrowPagination";
import { LineWobble } from "@uiball/loaders";

const Analytics = () => {

    const [websites, setWebsites] = useState(null)
    const [paginationWebsites, setPaginationWebsites] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(false)

    const [siteIndex, setSiteIndex] = useState(null)

    const enableSite = (website, index) => {
        if (window.confirm(`You are about to enable ${String.shorten(website.website, 20)}.`)) {
            setSiteIndex(index)
            setLoading(true)
            requests.makeGet('/api/enable/' + website.id, setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    let temp = websites.map((site) => {
                        if (site.id === website.id)
                            site["blacklisted"] = false
                        return site
                    })
                    setWebsites(null)
                    setIsLoading(true)
                    setWebsites(temp)
                    setIsLoading(false)
                }, "Website enabled successfully"
            )
        }
    }

    const disableSite = (website, index) => {
        if (window.confirm(`You are about to disable ${String.shorten(website.website, 20)} from loading your script`)) {
            setSiteIndex(index)
            setLoading(true)
            requests.makeGet('/api/disable/' + website.id, setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    let temp = websites.map((site) => {
                        if (site.id === website.id)
                            site["blacklisted"] = true
                        return site
                    })
                    setWebsites(null)
                    setIsLoading(true)
                    setWebsites(temp)
                    setIsLoading(false)
                }, "Website disabled successfully"
            )
        }

    }

    const getAnalytics = () => {
        requests.makeGet('/api/analytics', setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                if (res.data) {
                    setWebsites(res.data)
                    setIsLoading(false)
                    setError(null)
                } else {
                    setWebsites(null)
                    setError('')
                    setIsLoading(false)
                }
            }, null
        )
    }

    useEffect(() => {
        getAnalytics()
        //eslint-disable-next-line
    }, [])


    return (
        <div className="analytics-page">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container>
                <Grid grid={2} gap="10px">
                    <div>
                        <Typography variant="h4" className="bold">
                            Keep track of all websites using your plugin codes.
                        </Typography>
                        <Bodytext size={'20px'}>
                            Below is a table of all websites you have installed your pixel code on, you can diable a website from loading your plugin.
                        </Bodytext>
                    </div>
                </Grid>
                <Spacebox padding="10px" />
                {isLoading && (
                    <Skeleton variant="rounded" width="100%" height={600} />
                )}
                {error && (
                    <Typography textAlign="center">An error occured while fetching data | {error}</Typography>
                )}
                {websites && (
                    <>
                        {websites.length > 0 && (
                            <div>
                                {paginationWebsites && (
                                    <Grid grid={2} gap="20px">
                                        {paginationWebsites.map((website, index) => (
                                            <div key={index}>
                                                <div className="margin-auto" style={{ width: '60%', height: '5px', borderRadius: '10px 10px 0px 0px', background: 'var(--primary)'}}></div>
                                                <Flexbox alignItems="center" style={{ height: '100%' }}>
                                                    <div style={{ backgroundColor: '#f0f0f0', width: '50px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px 0px 0px 10px' }}>
                                                        <a href={website.website} target="_blank" rel='noreferrer' style={{ textDecoration: 'none' }}>
                                                            <Link style={{ color: 'var(--secondary)', fontSize: '20px' }} />
                                                        </a>
                                                    </div>
                                                    <Flexbox style={{ borderRadius: '0px 10px 10px 0px', backgroundColor: '#f0f0f0', width: 'calc(100% - 70px)', padding: "10px",  height: 'calc(100% - 20px)', }} justifyContent="space-between" alignItems="center">
                                                        <div>
                                                            <Typography>
                                                                {String.shorten(website.website, 30)}
                                                            </Typography>
                                                            <small style={{ opacity: .5 }}>Last Loaded: {(new Date(website.timestamp)).toString().substring(0, 15)}</small>
                                                        </div>
                                                        <div>
                                                            {website.blacklisted && (
                                                                <div style={{ textAlign: 'center' }}>
                                                                    {<IconButton onClick={() => enableSite(website, index)}>
                                                                        <CheckBoxOutlined style={{ color: 'var(--secondary)' }} />
                                                                    </IconButton>}
                                                                    {(loading && siteIndex === index) && <LineWobble size={50} speed={0.9} color="black" />}
                                                                    <Spacebox padding="2px" />
                                                                    {<small style={{ opacity: .5 }}>Enable Site</small>}
                                                                </div>
                                                            )}
                                                            {!website.blacklisted && (
                                                                <div style={{ textAlign: 'center' }}>
                                                                    {<IconButton onClick={() => disableSite(website, index)}>
                                                                        <CancelOutlined style={{ color: '#ff000070' }} />
                                                                    </IconButton>}
                                                                    {(loading && siteIndex === index) && <LineWobble size={50} speed={0.9} color="black" />}
                                                                    <Spacebox padding="2px" />
                                                                    {<small style={{ opacity: .5 }}>Disable Site</small>}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Flexbox>
                                                </Flexbox>
                                            </div>
                                        ))}
                                        <Spacebox padding="10px" />
                                    </Grid>
                                )}
                                <Spacebox padding="0px" />
                                <ArrowPagination data={websites} limit={8} setShowData={setPaginationWebsites} />
                            </div>
                        )}
                        {websites.length < 1 && (
                            <div>
                                <Typography textAlign="center"> It seems your pixel hasn't picked up any websites at this time.</Typography>
                            </div>
                        )}
                    </>
                )}
            </Container>
        </div>
    );
}

export default Analytics;