import { Container, IconButton, Skeleton, Typography } from "@mui/material";
import Grid from "../components/styles/Grid";
import { useSelector } from "react-redux";
import Spacebox from "../components/styles/Spacebox";
import Step from "../components/Step";
import { useState } from "react";
import Bodytext from "../components/Bodytext";
import Highlight from "react-highlight"
import { Close, Search } from "@mui/icons-material";
import Flexbox from "../components/Flexbox";
import Toast from "../components/Toast";
import useFetch from "../hooks/useFetch";
import String from "../utilities/Strings";
import ArrowPagination from "../components/ArrowPagination";
import CustomButton from "../components/styles/Custombutton";
import { useNavigate } from "react-router-dom";
import Copycode from "../components/Copycode";
import cookies from "../utilities/Cookies";

const Dashboard = ({ title }) => {
    document.querySelector("title").innerHTML = title

    const user = useSelector(state => state.user.value)
    console.log(user)

    const navigate = useNavigate()

    const { data: plugins, isLoading, error } = useFetch('/api/plugins')
    const [paginationPlugins, setPaginationPlugins] = useState(null)
    const [filteredPlugins, setFilteredPlugins] = useState(null)
    const [selectedPlugin, setSelectedPlugin] = useState(null)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [openPixel, setOpenPixel] = useState(false)
    const [openPrerequisite, setOpenPrerequisite] = useState(false)

    const [showSearch, setShowSearch] = useState(false)
    const [showSlidebar, setShowSlidebar] = useState(false)
    const [search, setSearch] = useState('')

    const filterPlugins = (word) => {
        setSearch(word)
        const res = plugins.filter(plugin => (plugin.name.toLowerCase()).includes(word.toLowerCase()))
        setFilteredPlugins(res)
    }

    const slidebar = (bool) => {
        if (bool) {
            setShowSlidebar(bool)
            setTimeout(() => {
                document.querySelector('.slide-bar-bg').classList.remove('hide')
                document.querySelector('.slide-bar-bg').classList.add('show')
                setTimeout(() => {
                    document.querySelector('.slide-bar').classList.add('show')
                }, 200);
            }, 500);
        } else {
            document.querySelector('.slide-bar').classList.remove('show')
            setTimeout(() => {
                document.querySelector('.slide-bar-bg').classList.add('hide')
                document.querySelector('.slide-bar-bg').classList.remove('show')
                setShowSlidebar(bool)
            }, 500);
        }
    }

    const openEditor = (plugin) => {
        if (plugin.editor === "true")
            navigate('/dashboard/editor/' + plugin.id)
        else {
            if (plugin.editor_link.includes('http'))
                window.location.href = plugin.editor_link + '?id=' + JSON.parse(cookies.getCookies('user')).id
            else
                navigate(plugin.editor_link + plugin.id)
        }
    }

    return (
        <div className="dashboard">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container>
                <Grid grid={2} gap={"20px"}>
                    <div>
                        <Typography variant="h4" className="light">
                            Welcome back <span className="bold">{user.firstname}</span>,
                        </Typography>
                        <Bodytext size={'20px'}>
                            Get set up and learn how to use our code snipets in your projects. We've got set-up guides for those just getting started, carefully documented guides to add some flair, and deeper dives for fine-tuning features to suit your needs.
                        </Bodytext>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <img src="/assets/developer.png" alt="developer" style={{ width: '50%', marginTop: -20 }} />
                    </div>
                </Grid>
                <Spacebox padding="20px" />
                <Step title="Pixel Installation ⚙️" open={openPixel} setOpen={setOpenPixel}>
                    <Bodytext size={'20px'}>
                        The pixel code should be added to the footer of your HTML/Website
                    </Bodytext>
                    <Spacebox padding="0px" />
                    <Copycode action={() => {
                        window.navigator.clipboard.writeText(`
                            <!-- Octakode Scripts Pixel Starts -->
                            <script src="https://app.octakode.xyz/v1/api/pixel?id=${user.id}"></script>
                            <!-- Octakode Scripts Pixel Ends -->`)
                        setToastMsg("Code Copied")
                        setSeverity('success')
                        setOpen(true)
                    }} />
                    <Highlight className="html">
                        {`<!-- Octakode Scripts Pixel Starts -->\n<script src="https://app.octakode.xyz/v1/api/pixel?id=i9UGbNNmvKONLeCib96U"></script>\n <!-- Octakode Scripts Pixel Ends -->\n`}
                    </Highlight>
                </Step>
                <Spacebox padding="10px" />
                <Step title="Prerequisite 🗒️" open={openPrerequisite} setOpen={setOpenPrerequisite}>
                    <Bodytext size={'20px'}>
                        For easier usability we recommend installing the below extension below 👇.
                        The extension helps you get the css selector name (For our non-developer customers) which our code requires in the plugin editor page.
                    </Bodytext>
                    <Bodytext size={'20px'}>
                        <a style={{ color: 'var(--secondary)' }} href="https://chrome.google.com/webstore/detail/copy-css-selector/kemkenbgbgodoglfkkejbdcpojnodnkg">Octakode Selector 🔗</a>
                    </Bodytext>
                    <Bodytext size={'20px'}>
                        The above chrome extension is compatible with
                    </Bodytext>
                    <div>
                        <img style={{ width: '150px' }} src="/assets/browsers_logos.png" alt="browsers" />
                    </div>
                </Step>
                <Spacebox padding="10px" />
                <Grid grid={3} gap="40px">
                    <div style={{ padding: '5px', borderRadius: '300px', background: '#F0F2F6', width: 'fit-content' }}>
                        <IconButton onClick={() => {
                            if (showSearch) {
                                setFilteredPlugins(null)
                                setSearch("")
                            }
                            setShowSearch(!showSearch)
                        }}>
                            {!showSearch && <Search style={{ color: 'grey', fontSize: '20px' }} />}
                            {showSearch && <Close style={{ color: 'grey', fontSize: '20px' }} />}
                        </IconButton>
                    </div>
                    <Typography variant="h5" className="bold" textAlign="center">
                        Available Plugins
                    </Typography>
                </Grid>
                <Spacebox padding="5px" />
                {(showSearch && paginationPlugins) && (
                    <div>

                        <input
                            value={search}
                            onChange={(e) => filterPlugins(e.target.value)}
                            placeholder="Search plugins"
                        />
                    </div>
                )}
                {error && (
                    <div>
                        <Spacebox padding="10px" />
                        <Flexbox justifyContent="center">
                            <Bodytext size="20px">
                                An error occured while getting plugins
                            </Bodytext>
                        </Flexbox>
                    </div>
                )}
                {isLoading && (
                    <div>
                        <Grid grid="3" gap="20px">
                            <Skeleton variant="rounded" width={"100%"} height={300} />
                            <Skeleton variant="rounded" width={"100%"} height={300} />
                            <Skeleton variant="rounded" width={"100%"} height={300} />
                        </Grid>
                    </div>
                )}
                {(plugins && !filteredPlugins) && (
                    <div>
                        <Spacebox padding="10px" />
                        {plugins.length > 0 && (
                            <div>
                                {paginationPlugins && <Grid grid={3} gap="40px">
                                    {paginationPlugins.map((plugin, index) => (
                                        <div className={"plugin " + plugin.type} key={index} onClick={() => {
                                            setSelectedPlugin(plugin)
                                            slidebar(true)
                                        }}>
                                            <div className="plugin-image">
                                                <img src={plugin.type === "css" ? "/assets/css_bg.png" : "/assets/js_bg.png"} alt={plugin.type} />
                                            </div>
                                            <div className="plugin-content">
                                                <Typography>
                                                    {plugin.name}
                                                </Typography>
                                                <Bodytext size="18px">
                                                    {String.shorten(plugin.description, 40)}
                                                </Bodytext>
                                            </div>
                                        </div>
                                    ))}
                                </Grid>}
                                <Spacebox padding="10px" />
                                <ArrowPagination data={plugins} limit={9} setShowData={setPaginationPlugins} />
                            </div>
                        )}
                    </div>
                )}
                {(filteredPlugins) && (
                    <div>
                        <Spacebox padding="10px" />
                        {filteredPlugins.length > 0 && (
                            <div>
                                <Grid grid={3} gap="40px">
                                    {filteredPlugins.map((plugin, index) => (
                                        <div className={"plugin " + plugin.type} key={index} onClick={() => {
                                            setSelectedPlugin(plugin)
                                            slidebar(true)
                                        }}>
                                            <div className="plugin-image">
                                                <img src={plugin.type === "css" ? "/assets/css_bg.png" : "/assets/js_bg.png"} alt={plugin.type} />
                                            </div>
                                            <div className="plugin-content">
                                                <Typography>
                                                    {plugin.name}
                                                </Typography>
                                                <Bodytext size="18px">
                                                    {String.shorten(plugin.description, 40)}
                                                </Bodytext>
                                            </div>
                                        </div>
                                    ))}
                                </Grid>
                            </div>
                        )}
                        {filteredPlugins.length < 1 && (
                            <div>
                                <Spacebox padding="10px" />
                                <Typography textAlign="center" style={{ color: '#ababab' }}>
                                    Opps | {search} not found
                                </Typography>
                                <Spacebox padding="20px" />
                            </div>
                        )}
                    </div>
                )}
            </Container>
            {showSlidebar &&
                <div className="slide-bar-bg hide"
                    style={{ top: 0, height: `100vh` }}
                >
                    {selectedPlugin && <div className="slide-bar">
                        <Flexbox justifyContent="flex-end" alignItems="center">
                            <IconButton onClick={() => slidebar(false)}>
                                <Close />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <Typography variant="h5" className="bold">
                            {selectedPlugin.name}
                        </Typography>
                        <Bodytext size="20px">
                            {selectedPlugin.description}
                        </Bodytext>
                        <Spacebox padding="5px" />
                        <Typography variant="h6">
                            HOW TO VIDEO
                        </Typography>
                        <Spacebox padding="5px" />
                        <iframe style={{ width: "100%", height: "200px", borderRadius: '10px' }} src={selectedPlugin.video_link !== 'null' ? selectedPlugin.video_link + "?rel=0" : 'https://www.youtube.com/embed/pxI4_h0y4CI?rel=0&controls=0'}
                            title="YouTube video player" frameBorder="0"
                            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"}
                            allowFullScreen></iframe>
                        <Spacebox padding="20px" />
                        <CustomButton backgroundColor="var(--primary)" borderRadius="100px" color="white" handleClick={() => {
                            openEditor(selectedPlugin)
                        }} padding="15px" className="fullwidth">
                            Editor
                        </CustomButton>
                    </div>}
                </div>}
        </div>
    );
}

export default Dashboard;