import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import ForgottenPassword from './pages/ForgottenPassword';
import NotFound from './pages/NotFound';
import Signup from './pages/Signup';
import Reset from './pages/reset';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Editor from './pages/Editor';
import Analytics from './pages/Analytics';
import Tutorials from './pages/Tutorials';
import Profile from './pages/Profile';
import HTMLEditor from './pages/HtmlEditor';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#000'
      },
      secondary: {
        main: '#7badf9'
      }
    },

    typography: {
      fontFamily: "Fira Sans Extra Condensed",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 800,
      fontSize: 20
    }
  })


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path='/' element={<Login title="Login | Octakode Scripts" />} />
          <Route exact path='/login' element={<Login title="Login | Octakode Scripts" />} />
          <Route exact path='/signup' element={<Signup title="Create An Account | Octakode Scripts" />} />
          <Route exact path='/forgot-password' element={<ForgottenPassword title="Forgotten Password | Octakode Scripts" />} />
          <Route exact path='/reset' element={<Reset title="Reset Password | Octakode Scripts" />} />
          <Route exact path='/dashboard' element={<Layout />} >
            <Route exact path="" element={<Dashboard title="Dashboard | Octakode Scripts"/>} />
            <Route exact path="editor/:id" element={<Editor title="Editor | Octakode Scripts"/>} />
            <Route exact path="editor/html/:id" element={<HTMLEditor title="Editor | Octakode Scripts"/>} />
            <Route exact path="analytics" element={<Analytics title="Analytics | Octakode Scripts"/>} />
            <Route exact path="tutorials" element={<Tutorials title="Tutorials | Octakode Scripts"/>} />
            <Route exact path="profile" element={<Profile title="Profile | Octakode Scripts"/>} />
          </Route>
          <Route exact path="*" element={<NotFound />}/>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
