import { useEffect, useState } from "react";
import { Avatar, Container, IconButton, Typography } from "@mui/material";
// import useFetch from "../hooks/useFetch";
import Grid from "../components/styles/Grid";
import Spacebox from "../components/styles/Spacebox";
import { useDispatch, useSelector } from "react-redux";
import Flexbox from "../components/Flexbox";
import { Check } from "@mui/icons-material";
import requests from "../handlers/requests";
import Toast from "../components/Toast";
import { updateuser } from "../features/users";
import { DotSpinner } from "@uiball/loaders";

const Profile = ({ title }) => {
    document.querySelector("title").innerHTML = title


    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(false)

    const [index, setIndex] = useState(0)
    const [avatar, setAvatar] = useState(0)

    const [fname, setFname] = useState('')
    const [email, setEmail] = useState('')
    const [lname, setLname] = useState('')


    const user = useSelector(state => state.user.value)
    const dispatch = useDispatch()

    const avatars = [
        'cat', 'chicken', 'deer', 'dog', 'dog2', 'giraffe', 'koala', 'meerkat', 'panda', 'puffer-fish', 'rabbit', 'sea-lion'
    ]

    const getAvatar = (url) => {
        if (avatar) {
            return avatar
        } else {
            let val = url.split("/avatars/")[1]
            return val.split(".")[0]
        }
    }

    const updateAvatar = (avatar) => {
        if (avatar) {
            setLoading(true)
            requests.makePost('/api/user/update', { avatar: `/avatars/${avatar}.png` }, setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    let obj = { ...user }
                    obj['avatar'] = `/avatars/${avatar}.png`
                    dispatch(updateuser(obj))
                },
                "Avatar updated successfully"
            )
        }
    }
    const updateDetails = (data) => {
        if (data) {
            setLoading(true)
            requests.makePost('/api/user/update', { ...data }, setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    let obj = { ...user }
                    obj['firstname'] = data.firstname
                    obj['lastname'] = data.lastname
                    dispatch(updateuser(obj))
                },
                "Details updated successfully"
            )
        }
    }

    const setDetails = () => {
        setFname(user.firstname)
        setLname(user.lastname)
        setEmail(user.email)
    }

    useEffect(() => {
        if (user)
            setDetails()
        // eslint-disable-next-line
    }, [])


    return (
        <div className="profile-page">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Spacebox padding="20px" />
            <Container>
                <div className="wide margin-auto">
                    <Grid gap={"20px"} grid={2}>
                        <div onClick={() => setIndex(0)} className="tab" style={{ borderRadius: '20px 0px 0px 0px', background: index === 0 ? '#f7f7f7' : 'transparent', padding: '10px 20px', cursor: 'pointer', transition: '.5s ease' }}>
                            <Typography variant="body1">
                                Avatar
                            </Typography>
                        </div>
                        <div onClick={() => setIndex(1)} className="tab" style={{ borderRadius: '0px 20px 0px 0px', background: index === 1 ? '#f7f7f7' : 'transparent', padding: '10px 20px', cursor: 'pointer', transition: '.5s ease' }}>
                            <Typography variant="body1">
                                Details
                            </Typography>
                        </div>
                    </Grid>
                </div>
                <div className="content wide margin-auto" style={{ background: '#f7f7f7', borderRadius: '0px 0px 20px 20px' }}>
                    {index === 0 && (
                        <div style={{ padding: '20px' }}>
                            <Flexbox justifyContent="center" alignItems="center">
                                <Avatar src={avatar ? `/avatars/${avatar}.png` : `${user.avatar}`} alt={`${user.firstname}`} style={{ width: '250px', height: '250px', border: '5px solid var(--secondary)' }} />
                            </Flexbox>
                            <Spacebox padding="20px" />
                            <Grid grid={6} gap="10px" style={{ flexWrap: 'wrap' }} justifyContent="flex-end">
                                {avatars.map((avatar, index) => (
                                    <div key={index} onClick={() => setAvatar(avatar)}>
                                        <Avatar className="pointer" src={`/avatars/${avatar}.png`} alt={`${avatar}`} style={{ border: getAvatar(user.avatar) === avatar ? '5px solid var(--secondary)' : '5px solid transparent', height: '70px', width: '70px' }} />
                                    </div>
                                ))}
                            </Grid>
                            <Flexbox justifyContent="flex-end" style={{ background: '#f7f7f7', borderRadius: '50px', marginRight: '-60px' }}>
                                <IconButton onClick={() => updateAvatar(avatar)}>
                                    {!loading && <Check style={{ color: 'var(--secondary)', fontSize: '35px' }} />}
                                    {loading && <DotSpinner size={30} speed={0.9} color="var(--secondary)" />}
                                </IconButton>
                            </Flexbox>
                        </div>
                    )}
                    {(index === 1 && user) && (
                        <div style={{ padding: '20px' }}>
                            <input
                                type="text"
                                value={fname}
                                onChange={(e) => setFname(e.target.value)}
                                placeholder="First Name"
                            />
                            <Spacebox padding="10px" />
                            <input
                                type="text"
                                value={lname}
                                onChange={(e) => setLname(e.target.value)}
                                placeholder="Last Name"
                            />
                            <Spacebox padding="10px" />
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email Address"
                                disabled
                            />
                            <Spacebox padding="20px" />
                            <Flexbox justifyContent="flex-end" style={{ background: '#f7f7f7', borderRadius: '50px', marginRight: '-60px' }}>
                                <IconButton onClick={() => updateDetails({firstname: fname, lastname: lname})}>
                                    {!loading && <Check style={{ color: 'var(--secondary)', fontSize: '35px' }} />}
                                    {loading && <DotSpinner size={30} speed={0.9} color="var(--secondary)" />}
                                </IconButton>
                            </Flexbox>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
}

export default Profile;