import { IconButton } from "@mui/material";
import Flexbox from "./Flexbox";
import { ContentCopy } from "@mui/icons-material";
import Spacebox from "./styles/Spacebox";

const Copycode = ({action}) => {
    return (
        <Flexbox alignItems="center">
            <IconButton onClick={action}>
                <ContentCopy style={{ fontSize: '18px' }} />
            </IconButton>
            <Spacebox padding="2px" />
            <small>Copy Code</small>
        </Flexbox>
    );
}

export default Copycode;