import { Container, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import { Link } from "react-router-dom";
import Flexbox from "../components/Flexbox";

const NotFound = () => {
    return (
        <Container className="not-found">
            <Flexbox alignItems="center" justifyContent="center" style={{ height: '80vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spacebox padding="30px" />
                    <Typography className="not-found-text" textAlign="center">
                        Opps! Page not found
                    </Typography>
                    <Typography style={{ fontSize: 15 }} textAlign="center">
                        Head back to <Link to="/">dashboard</Link>
                    </Typography>
                </div>
            </Flexbox>
        </Container>
    );
}

export default NotFound;