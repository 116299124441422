import { useNavigate } from "react-router-dom";
import Flexbox from "./Flexbox";
import Spacebox from "./styles/Spacebox";
import { IconButton } from "@mui/material";

const Sidebar = () => {
    const navigate = useNavigate()

    const logout = async () => {
        let res = await fetch('/api/logout')
        res = await res.json()
        if(res.success) {
            navigate('/')
        } else {
            window.alert("An error occured, try again")
        }
    }

    const menus =  [
        {
            title: 'Home',
            icon: '/svgs/home.svg',
            action: () => navigate('/dashboard'),
            path: '/dashboard'
        },
        {
            title: 'Analytics',
            icon: '/svgs/activity.svg',
            action: () => navigate('/dashboard/analytics'),
            path: '/dashboard/analytics'
        },
        {
            title: 'Tutorials',
            icon: '/svgs/video.svg',
            action: () => navigate('/dashboard/tutorials'),
            path: '/dashboard/tutorials'
        },
        {
            title: 'Logout',
            icon: '/svgs/log-out.svg',
            action: logout,
            path: '/'
        },
    ]
    return (
        <div className="sidebar">
            <Flexbox alignItems="center" justifyContent="center" style={{width: '70px', backgroundColor: 'black', height: '100vh'}}>
                <div>
                    {menus.map((item, index) => (
                        <div key={index}>
                            <Spacebox padding="10px" />
                            <IconButton onClick={item.action}>
                                <img src={item.icon} alt={item.title} style={{width: '20px', filter: 'invert(1)', opacity: item.path === window.location.pathname ? 1 : .5}}/>
                            </IconButton>
                            <Spacebox padding="0px" />
                            {item.path === window.location.pathname && (
                                <Flexbox justifyContent={"center"}>
                                    <div style={{width: '10px', height: '10px', background: 'white', borderRadius: '100px'}}></div>
                                </Flexbox>
                            )}
                            <Spacebox padding="10px" />
                        </div>
                    ))}
                </div>
            </Flexbox>
        </div>
    );
}
 
export default Sidebar;