const searchAlgo = (word, searchItem, data, setSearch, setFilteredData) => {
    setSearch(word)
    if(word === '')
        setFilteredData(null)
    else {
        if(data) {
            const res = data.filter(datium => (datium[searchItem].toLowerCase()).includes(word.toLowerCase()))
            setFilteredData(res)
        } else {
            setFilteredData([])
        }
    }
}

module.exports = { searchAlgo }