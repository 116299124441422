import { useState } from "react";
import { Container, IconButton, Skeleton, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import useFetch from "../hooks/useFetch";
import Grid from "../components/styles/Grid";
import ArrowPagination from "../components/ArrowPagination";
import Flexbox from "../components/Flexbox";
import { Close, PlayCircleFilledOutlined } from "@mui/icons-material";
import { searchAlgo } from "../utilities/algorithms";

const Tutorials = ({ title }) => {
    document.querySelector("title").innerHTML = title

    const { data: tutorials, error, isLoading } = useFetch("/api/tutorials")
    const [paginationTut, setPaginationTut] = useState(null)
    const [filteredTut, setFilteredTut] = useState(null)
    const [video, setVideo] = useState(null)


    const [search, setSearch] = useState("")


    return (
        <div className="tutorials-page">
            <Container>
                <Spacebox padding="40px" />
                <div className="mid margin-auto">
                    <Typography variant="h4" textAlign="center">
                        WHAT DO YOU NEED HELP WITH?
                    </Typography>
                    <input
                        value={search}
                        onChange={(e) => {
                            searchAlgo(e.target.value, "title", tutorials, setSearch, setFilteredTut)
                            if(search === "") {
                                setFilteredTut(null)
                            }
                        }}
                        placeholder="Search for..."
                        style={{ fontSize: '30px', borderRadius: '100px' }}
                        className="tutorial-search"
                    />
                </div>
                <Spacebox padding="20px" />
                {isLoading && (
                    <Grid grid={3} gap='20px'>
                        <Skeleton width={"100%"} height={200} variant="rounded" />
                        <Skeleton width={"100%"} height={200} variant="rounded" />
                        <Skeleton width={"100%"} height={200} variant="rounded" />
                        <Skeleton width={"100%"} height={200} variant="rounded" />
                        <Skeleton width={"100%"} height={200} variant="rounded" />
                        <Skeleton width={"100%"} height={200} variant="rounded" />
                    </Grid>
                )}
                {error && (
                    <Typography textAlign="center">An error occurred please try again</Typography>
                )}
                {tutorials && (
                    <div>
                        {(tutorials.length > 0 && !filteredTut) && (
                            <div>
                                {paginationTut && (
                                    <Grid grid={3} gap="20px">
                                        {paginationTut.map((tut, index) => (
                                            <div key={index} className="tutorial" onClick={() => setVideo(tut.youtube)}>
                                                <Typography style={{ color: 'white' }} variant="h5">
                                                    {tut.title}
                                                </Typography>
                                                <Spacebox padding='30px' />
                                                <Flexbox justifyContent="flex-end">
                                                    <PlayCircleFilledOutlined style={{ color: 'white' }} className="play-btn" />
                                                </Flexbox>
                                            </div>
                                        ))}
                                    </Grid>
                                )}
                                <Spacebox padding="30px" />
                                <ArrowPagination data={tutorials} setShowData={setPaginationTut} limit={9} />
                            </div>
                        )}

                        {!tutorials.length > 0 && (
                            <Typography textAlign="center">There are no tutorials at this time</Typography>
                        )}

                        {(filteredTut) && (
                            <div>
                                {filteredTut.length > 0 ? (
                                    <Grid grid={3} gap="20px">
                                        {filteredTut.map((tut, index) => (
                                            <div key={index} className="tutorial">
                                                <Typography style={{ color: 'white' }} variant="h5">
                                                    {tut.title}
                                                </Typography>
                                                <Spacebox padding='10px' />
                                                <Flexbox justifyContent="flex-end">
                                                    <PlayCircleFilledOutlined style={{ color: 'white' }} className="play-btn" />
                                                </Flexbox>
                                            </div>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Typography textAlign="center">No result for {search} found </Typography>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </Container>
            {video && <div className="popup">
                <div className="mid">
                    <Flexbox alignItems="center" justifyContent="flex-end">
                        <IconButton onClick={() => setVideo(null)}>
                            <Close style={{color: 'white'}} />
                        </IconButton>
                    </Flexbox>
                    <Spacebox padding="10px" />
                    <iframe width="100%" height="315" src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
            </div>}
        </div>
    );
}

export default Tutorials;