import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Spacebox from "./styles/Spacebox";
import { useDispatch } from 'react-redux'
import { useEffect, useState } from "react";
import { updateuser } from "../features/users";
import Flexbox from "./Flexbox";
import { Typography } from "@mui/material";
import Toast from "./Toast";
import cookies from "../utilities/Cookies";
import { Jelly } from '@uiball/loaders';
import Sidebar from "./Sidebar";
import { isMobile } from "react-device-detect"


const Layout = () => {

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)

    const navigate = useNavigate()

    const logout = async () => {
        let res = await fetch('/api/logout')
        res = await res.json()
        if(res.success) {
            navigate('/')
        } else {
            window.alert("An error occured, try again")
        }
    }


    useEffect(() => {
        const stringify_user = cookies.getCookies('user');
        if (stringify_user === '' || stringify_user === undefined || stringify_user === null || stringify_user.length < 10) {
            navigate('/');
        } else {
            const user = JSON.parse(stringify_user)

            fetch(`/api/user/${user.id}`)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        dispatch(updateuser(res.user))
                        setLoading(false)
                        setSuccess(true)
                        setError(false)
                    } else {
                        if (res.error === "Access Denied")
                            navigate('/')

                        console.log(res.error)
                        setToastMsg(res.msg)
                        setOpen(true)
                        setSeverity('error')
                        setLoading(false)
                        setError(true)
                    }
                }).catch(err => {
                    console.log("Error message: ", err.message)
                    setToastMsg('An error occured, Try again')
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                    setError(true)
                })
        }
        //eslint-disable-next-line
    }, [])

    return (
        <div>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            {!isMobile && <div>
                {loading && <Flexbox justifyContent="center" alignItems="center" className="full">
                    <div className="small" style={{ textAlign: "center" }}>
                        <Flexbox justifyContent="center"><Jelly size={50} speed={0.9} color="black" /></Flexbox>
                    </div>
                </Flexbox>}
                {success && <div className="layout" style={{ maxHeight: '100vh', height: '100vh', overflowY: 'hidden' }}>
                    <Flexbox>
                        <Sidebar />
                        <div style={{ width: '100%' }}>
                            <Header />
                            <div style={{ maxHeight: 'calc(100vh - 76px)', overflowY: 'scroll' }}>
                                <Spacebox padding="20px">
                                    <Outlet />
                                </Spacebox>
                            </div>
                        </div>
                    </Flexbox>
                </div>}
                {error && <Flexbox justifyContent="center" alignItems="center" className="full">
                    <div className="mid" style={{ textAlign: "center" }}>
                        <img src="/svgs/notFound.svg" alt="scripts" style={{ width: '200px' }} />
                        <Typography variant="h6" className="bold"> There seems to be a problem </Typography>
                    </div>
                </Flexbox>}
            </div>}
            {isMobile && <div>
                <Spacebox padding="20px" />
                <div style={{ textAlign: 'center' }}>
                    <img src="/assets/desktop.png" alt="desktop" style={{ width: '100px' }} />
                </div>
                <Typography textAlign="center">
                    View Dashboard with laptop
                </Typography>
                <Typography style={{ fontSize: '16px' }} textAlign="center">
                    <span onClick={logout} className="pointer">Login</span>
                </Typography>
            </div>}
        </div>
    );
}

export default Layout;