import { useNavigate } from "react-router-dom";
import Flexbox from "./Flexbox";
import { Avatar, Container, IconButton } from "@mui/material";
import { useSelector } from 'react-redux';

const Header = () => {

    const navigate = useNavigate()
    const user = useSelector(state => state.user.value)

    return (
        <div className="header" style={{ padding: '10px 0px', borderBottom: '1px solid #f0f0f0', position: 'sticky', top: '0px' }}>
            <Container>
                <Flexbox alignItems="center" justifyContent="space-between">
                    <div className="">
                        <img src="/assets/logo.png" alt="octakode-scripts" style={{ width: '250px' }} />
                    </div>
                    {user && (
                        <IconButton onClick={() => {
                            navigate('/dashboard/profile')
                        }}>
                            <Avatar src={`${user.avatar}`} alt={`${user.firstname}`} />
                        </IconButton>
                    )}
                </Flexbox>
            </Container>
        </div>
    );
}

export default Header;